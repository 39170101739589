.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.css-2y464i-MuiInputBase-root-MuiFilledInput-root::before {
  border-bottom: none !important;
  border-radius: 50px !important;
}

.css-2y464i-MuiInputBase-root-MuiFilledInput-root:hover {
  border-bottom: none !important;
  /* border-radius: 50px !important; */
}

.css-10botns-MuiInputBase-input-MuiFilledInput-input {
  padding-top: 8px !important;
}

.css-80sdn5-MuiPaper-root-MuiDialog-paper {
  max-width: 700px !important;
  min-height: 600px !important;
  padding: 6px !important;
  border-radius: 8px !important;
  border-top-right-radius: 30% !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  background-color: red;
}

.MuiDropzoneArea-root {
  width: 100% !important;
  overflow: hidden;
  border-radius: 8px !important;
  background-color: #efefef !important;
  border: 1px solid #c0bebe !important;
}

.MuiDropzoneArea-textContainer {
  text-align: center;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.MuiDropzoneArea-root {
  max-height: 135px !important;
  min-height: 135px !important;
}

.css-ypiqx9-MuiDialogContent-root {
  width: 90% !important;
  padding: 20px 40px !important;
}

.MuiDropzoneArea-text.MuiTypography-h5 {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #17256e !important;
  margin-left: 80px !important;
  margin-top: 54px !important;
}

/* .MuiTypography-h5::before {
  content: '';
  display: inline-block; */
/* width: 45px; Adjust width and height according to your image */
/* height: 56px; */
/* background-image: url('../public/upload-dull.png'); */
/* background-size: cover;
  margin-right: 5px;
  margin-left: 20px;
} */

.MuiDropzoneArea-icon {
  display: none !important;
}

.fileupload input::placeholder {
  font-size: 12px;
}

/* Define scrollbar styles
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
} */


/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.content {
  display: flex;
  flex-direction: column;
  width: calc(100% - 85px);
}

.content-wrap {
  max-width: 100%;
  margin: 20px 0;
  padding: 0 50px;
}

.vra-content-card {
  background: #f9f9f9;
  border-radius: 8px !important;
  border: none !important;
  box-shadow: 0px 4px 4px 0px #00000040 !important;
}

.vra-content-subcard {
  margin: 30px 0;
  box-shadow: 0px 4px 4px 0px #00000040;
  border: none !important;
  border-radius: 8px !important;

}

.vra-card-heading {
  color: #17256E !important;
  font-size: 2em !important;

}

.card-filter-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-filter-body {
  padding: 10px !important;
}

.breadcrumb-div {
  margin-top: 20px;
  padding: 0 50px;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 600px) {

  .card-filter-section {
    flex-direction: column;
    gap: 10px;
  }

  .content-wrap {
    padding: 0 10px;
  }

  .breadcrumb-div {
    padding: 0 10px;
  }

  .content {
    padding: 0 20px;
  }

  .modal-whale {
    display: none;
    ;
  }
}


.input-breaker {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.vra-modal-btn {
  max-width: 220px;
  height: 45px;
}

.vra-label {
  font-size: 16px !important;
  font-weight: 500 !important;
  margin-bottom: 12px !important;

}

@media screen and (max-width: 600px) {
  .vra-modal-input {
    width: 130px !important;
  }

  .vra-modal-btn {
    width: 130px !important;
    padding: 8px 10px !important;
    min-width: 130px !important;

  }

  .bottom-bar {
    padding: 0 10px !important;
  }

  .bottom-bar li button {
    padding: 0px !important;
    margin: 0px !important;
    min-width: 20px !important;
  }

  .vra-label {
    font-size: 12px !important;
    margin-bottom: 8px !important;

  }

}

.bottom-bar {
  height: 60px;
  margin: 10px 0;
  padding: 0 50px;
}

table tr td:first-child {
  text-wrap: nowrap;
}


.profile-heading {
  margin: 0px 50px;
}


.profile-heading h5 {
  color: #17256e;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 32px;
  margin: 20px 0 35px;

}


.profile-page-icon {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 5px solid white;
  position: absolute;
  left: 50%;
  top: 0%;
  transform: translate(-50%, -50%);
  color: grey;
  background-color: white;

}

.profile-card-section {
  width: calc(100% - 100px);
  margin-top: 60px;
  margin-bottom: 20px;
  background-color: #f3f3f3;
  border-radius: 30px;
  padding: 20px 100px;
  position: relative;
  margin: 0 50px;

}

.profile-input {
  max-width: 45% !important;
  flex-basis: 50% !important;
}

.profile-btn {
  width: 240px !important;
}

@media screen and (max-width: 800px) {

  .profile-card-section {
    padding: 20px 40px;
  }

  .profile-btn {
    width: 180px !important;
    padding: 8px 15px !important;
    font-size: 15px !important;
  }

  .profile-heading {
    margin: 0px 50px 30px 50px;
  }

  .profile-input {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }

  .vra-profile-btn {
    width: 50% !important;
    padding: 8px 15px !important;
  }

}

@media screen and (max-width: 600px) {

  .profile-heading {
    margin: 0 10px 20px 10px
  }

  .profile-card-section {
    margin: 20px 0;
    width: 100% !important;
  }
}


.width-sidebar {
  width: 85px;
}

.map-header-width {
  position: absolute;
  top: 0;
  left: 80px;
  right: 0;
  z-index: 1000;

}


.mobile-sidebar-opener {
  width: 35px;
  height: 30px;
  background-color: #f9f9f9;
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 0 20px 20px 0;
  border: 1px solid #dbdbdb;
  position: fixed;
  left: -35px;
  top: 55px;
  z-index: 99999;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}


.mobile-sidebar-opener svg {
  font-size: 10px;
}

.mobile-sidebar-opener.open {
  left: 83px;
}


.mobile-sidebar-opener .bars {
  height: 27px;
}

.mobile-sidebar-opener.open .close {
  height: 28px;

}


.width-sidebar.open .css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  left: 0 !important;
  transition: left 0.3s ease-in-out;

}

@media screen and (max-width: 500px) {
  .width-sidebar {
    width: 0;
  }


  .css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
    left: -120px !important;
  }

  .content {
    width: 100% !important;
    padding: 0px 5px;
  }

  .map-header-width {
    left: 0;
  }

  .mobile-sidebar-opener {
    display: flex;
  }
}


.uploaded-file-wrap {
  box-shadow: 0px 4px 4px 0px #00000040;
  width: 320px;
  border-radius: 12px;
  background: white;
  padding: 10px;
  padding-left: 15px;
  display: flex;
  align-items: center;
  gap: 20px;

}

.MuiGrid-root.MuiGrid-container.css-1xzqqmo-MuiGrid-root {
  gap: 10px;
}

.add-user-modal-wrap {
  padding: 10px 70px;

}

.input-x-wrap {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

.input-full-wrap .w-100 {
  text-align: center;
}



@media screen and (max-width: 500px) {

  .add-user-modal-wrap {
    padding: 10px 15px !important;

  }

  .input-full-wrap {
    gap: 5px;
  }

  .input-full-wrap .vra-modal-input {
    width: 100% !important;

  }

  .input-x-wrap {
    padding-left: 20px !important;
    padding-right: 20px !important;

  }

  .input-x-left-wrap {
    padding-left: 40px !important;
    padding-right: 20px !important;
  }

  .input-x-wrap.dash .vra-modal-input {
    width: 100% !important;
  }

  .heading-m-0 {
    margin-left: 0 !important;
  }

  .modal-img-hidden {
    display: none !important;
  }

  .button-narrow {
    width: 150px !important;
  }

  .box-w-0 {
    width: 0px !important;
  }

  .box-p-0 {
    padding: 0 20px !important;
  }

  .header-container {
    padding-right: 0px !important;
  }
}

@media screen and (max-width: 400px) {
  .uploaded-file-wrap {
    width: 260px;
    gap: 5px;
  }

  .css-ypiqx9-MuiDialogContent-root {
    padding: 20px 5px !important;
  }

}

.rightOffcanvus {
  background-color: #f0f0f0 !important;
  width: 280px !important;
  padding: 10px;
  border-radius: 20px 0 0 20px;


}

.offcanvus-toggle {
  font-weight: 600;
  height: 34px;
  width: 69px;
  border: 0;
  position: fixed;
  z-index: 999;
  top: 120px;
  right: 0;
  outline: none;
  border-radius: 5px 0 0 5px;
  color: #2d397a;
}

.offcanvus-title {
  color: #0A1967 !important;
  font-size: 27px !important;
  font-weight: 700 !important;
}

.custom-close-btn {
  border: none;
  background-color: transparent;
  color: #0A1967;
  outline: none;
}

.offcanvus-header {
  display: flex !important;
  flex-direction: row-reverse;
  padding: 70px 10px 30px 10px !important;
}

.manageData {
  width: 100%;
  border-top: 1px solid rgb(131, 131, 131);
  position: relative;
  margin-bottom: 30px;
}

.manageData span {
  padding: 4px 4px 8px 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f0f0f0 !important;
  color: #616161;

}

.nav-tabs {
  justify-content: space-around;
}

.nav-tabs li button {
  color: #616161;
}

.nav-tabs li button:hover {
  color: #616161;
}

.nav-tabs li button.active {
  background-color: #0a1967d9 !important;
  color: #fff !important;
}

.right-accordion-header .accordion-button {
  padding: 7px 15px !important;
  background-color: #f0f0f0 !important;
  box-shadow: none !important;
}

.accordion-button::after {
  content: unset !important;
}

.right-accordion-header .accordion-button.collapsed {
  background-color: #f0f0f0 !important;

}

.right-accordion-body {
  background-color: #f0f0f0 !important;
  padding: 7px 15px !important;
  overflow-x: auto;

}



.wemo-checkbox {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  user-select: none;
}

.wemo-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: -8px;
  left: 0;
  height: 20px;
  width: 22px;
  background-color: #eee;
  border-radius: 3px;
  border: 2px solid #0a1967d9;
}

.wemo-checkbox:hover input~.checkmark {
  background-color: #ccc;
}

.wemo-checkbox input:checked~.checkmark {
  background-color: #0a1967d9;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.wemo-checkbox input:checked~.checkmark:after {
  display: block;
  left: 0px;
  height: 10px;
  width: 20px;
  background-image: url("assets/check.svg");
  background-size: contain;
  background-repeat: no-repeat;
  top: 4px;
}

.right-accordion-p {
  font-size: 12px;
  margin-bottom: 5px;
}

.right-button-wrap {
  display: flex;
  align-items: center;
  gap: 5px;
}

.right-btn {
  width: 100%;
  line-height: 0px !important;
  height: 35px;
}

.offcanvus-heading {
  color: #0A1967 !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  margin-left: 2px;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
}

.offcanvus-heading::after {
  content: "";
  position: absolute;
  width: 40px;
  height: 2px;
  border-radius: 10px;
  right: -45px;
  background-color: #0A1967;
  top: 11px;
}



.processing-slide {
  width: 250px;
  height: 300px;
  background-color: #f7f7f7;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  position: fixed;
  right: -250px;
  top: 30%;
  z-index: 1000;
  border-radius: 5px 0 0 5px;
  padding: 10px;
  transition: all 0.3s ease-in-out;

}

.processing-slide.open {
  right: 0%;
}

.processing-slide .header {
  height: 45px;
  width: 100%;
  background-color: #0A1967;
}

.processing-tabs {
  border-bottom: none !important;
  display: flex !important;
  gap: 10px;
  justify-content: unset !important;
  flex-direction: row;
  margin-bottom: 15px;

}

.processing-tabs li {
  width: calc(50% - 5PX);
}

.processing-tabs li button {
  width: 100%;
}

.processing-p {
  font-size: 13px;
  margin: 0;
  padding: 5px;
  border-bottom: 1px solid #dedede;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.processing-slide .opener {
  height: 30px;
  width: 25px;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px 0 0 5px;
  box-shadow: -2px 0 1px rgba(0, 0, 0, 0.2);
  left: -25px;
  top: 20px;
  position: absolute;
  cursor: pointer;
}

.processing-slide .opener svg {
  transition: all 0.3s ease-in-out;
}


.processing-slide.open .opener svg {
  transform: rotate(180deg);
}

.tab-content-custom {
  height: 235px;
  overflow: auto;
}





.animal-form {
  padding: 10px 20px;
}

.animal-form-input {
  width: 100%;
  height: 35px;
  border-radius: 20px;
  border: 1px solid #17256e;
  margin: 5px 0;
  padding: 5px 20px;
  outline: none;
  font-size: 14px;
}


.animal-form button {
  width: 200px;
  height: 35px;
  border: 1px solid #17256e;
  background-color: #17256e;
  color: white;
  font-weight: 400;
  margin: 5px 0;
  border-radius: 83px;
  font-size: 15px;
}

.finish-job-body {
  background-color: #f7f7f7;
  border-radius: 20px;
  padding: 0px !important;
}

.finish-job-modal-content {
  border-radius: 20px !important;
  overflow: hidden !important;
}

.finish-job-body .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 15px;
  background-color: rgb(23, 37, 110);
  opacity: 0.9
}


.finish-job-body .header h5{
  margin: 0 !important;
  color:#fff;
  font-size:18px;
}
.finish-job-body .header svg path{
  stroke:#fff;
}
.finish-job-body .body{
  padding:20px;
}
.finishJob-p span:first-of-type{
  font-weight:700;
  width:150px;
  display:inline-block;
}

.finishJob-p {
  margin-bottom: 6px;
  font-size: 15px;
} 

.result-blocks-wrap{
  display:flex;
  gap:10px;
  flex-wrap: wrap;
}

.finish-job-p{
  font-size: 14px;
  margin-bottom: 5px;
}
.finish-job-p span:first-of-type{
  font-weight: 700;
  width:150px;
  display: inline-block;
}

.result-block{
  width: calc(50% - 5px);
  border: 1px solid rgba(23, 37, 110, 0.7);
  border-radius: 15px;
  padding: 15px 10px;
  background-color: #0a196724;
  display: flex;
  flex-direction: column;
  gap: 2px;

} 
.result-block p{
  font-size: 14px;
  margin: 0
}
.result-block p span:first-of-type{
  font-weight: 700;
  /* width: 110px; */
  display: inline-block;  }

  .finish-job-btn{
    position: fixed;
    top: 50%;
    right: 0%;
    z-index:9999;
  }

  .layer-active-btn{
    background-color: #0a1967d9 !important;
    color: white !important;
    border-radius: 5px !important;  }