.Getstart-wrap {
  background-image: url(../../pics/bg-nowhale.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh;
}

.whale-wrap {
  background-image: url('../../pics/whale.png');
  /* Replace with your actual image path */
  background-size: cover;
  background-repeat: round;
  width: 80vw;
  height: 79vh;
  position: absolute;
  top: 144px;
  right: 0;
  z-index: 9;
}

.banner-p {
  font-size: 24px;
}

.banner-sub-p {
  font-size: 20px;
  font-weight: 300;
}

.banner-section-wrap {
  margin-top: 180px;
  margin-left: 50px;

}

.banner-btn {
  border-radius: 25px;
  width: 30%;
  color: white;
  margin-top: 25px;
  box-shadow: 1px 1px 7px -2px rgba(0, 0, 0, 0.75);

  /* Vendor prefixes for webkit and moz browsers */
  -webkit-box-shadow: 1px 1px 7px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 1px 7px -2px rgba(0, 0, 0, 0.75);

}

.banner-heading-wrap {
  margin-top: 100px;
  font-size: 70px;
  line-height: 70px;
  margin-left: 40px;
  z-index: 99;

}

@media screen and (max-width: 992px) {
  .whale-wrap{
    display: none;
  }
  .banner-section-wrap{
    margin-left: 0;
    margin-top: 30px;


  }
  .banner-heading-wrap{
    margin-left: 0px;
  }
}

@media screen and (max-width: 450px) {
  .banner-heading-wrap {
    font-size: 40px;
    line-height: 40px;

  }
  .banner-p{
    font-size: 16px;
  }
  .banner-sub-p{
    font-size: 12px;
  }

  .banner-section-wrap{
    margin-top: 10px;
  }
} 
