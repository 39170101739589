.header-row {
    margin: 0 30px !important;
}

.vra-navbar {
    border-radius: 20px;
    background-color: rgba(192, 192, 192, 0.3);
    /* Fallback for backgroundColor */
    color: white;
    margin: 0 30px;
    margin-bottom: 10px;
    
    /* Replace 'marginBottom' with the actual value */
    box-shadow: 1px 1px 7px -2px rgba(0, 0, 0, 0.75);
    /* Box shadow for all browsers */
    -webkit-box-shadow: 1px 1px 7px -2px rgba(0, 0, 0, 0.75);
    /* Vendor prefix for webkit browsers */
    -moz-box-shadow: 1px 1px 7px -2px rgba(0, 0, 0, 0.75);
    /* Vendor prefix for Firefox */

}

.vra-nav-link {
    color: white;
    margin-right: 20px;
}

.nav-logo {
    height: 44px;
    width: 100px;
}

@media screen and (max-width: 450px) {
    .header-row {
        margin: 0 10px !important;
    }

    .vra-nav-link {
        margin-right: 5px;
    }

    .nav-logo {
        height: 30px;
        width: 75px;
    }
    .vra-navbar{
        margin: 0 10px;
    margin-bottom: 10px;

    }
}