.filter-search-input {
    width: 250px;
    border-radius: 25px;
    background: #F2F2F2;
    box-shadow: 0px 4px 4px 0px #00000040;
}

.filter-add-button {
    border-radius: 16px;
    width: 250px;
    background: #17256E;
    box-shadow: 0px 4px 4px 0px #00000040;

}


@media screen and (max-width: 400px) {
    .filter-search-input,
    .filter-add-button 
     {
        width: 200px;   
    }
    
  
  }
  