.custom-modal-wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
}

.select-tool-div {
    background-color: white !important;
    width: 600px !important;
    border-radius: 10px !important;
    position: relative !important;
    overflow: hidden;
}

.select-tool-option-wrap {
    width: 100%;
    display: flex;
    padding: 10px 15px;
    cursor: pointer;
}

.select-tool-option {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    width: 150px;
    border-right: 1px solid #CBCBCB;

}

.select-tool-button {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.query-modal {
    background-color: white;
    width: 800px;
    border-radius: 10px;
    position: absolute;
    z-index: 1000;

}

.vessel-parameter-wrap {
    display: flex;
    gap: 6px;
    padding: 10px 20px;
    margin-top: 20px;
    height: 400px;

}

.vessel-parameter-modal {
    background-color: white;
    width: 90%;
    border-radius: 10px;
    position: absolute;
    z-index: 10;
    margin: 0 50px;

}

.vessel-table-card {
    padding: 50px 10px 10px 10px;
    border: 1px solid #CBCBCB;
    border-radius: 5px;
    background-color: #EBEBEB;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 100%;
    width: 100%;
    overflow-x: auto;

}

.vessel-table-month-card {
    padding: 10px 50px;
    border: 1px solid #CBCBCB;
    border-radius: 5px;
    background-color: #EBEBEB;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    /* min-height: 60px; */

}

.vessel-table {
    overflow-y: hidden;
}

.vessel-table table th {
    text-wrap: nowrap;
    text-align: center;
    font-size: 10px;
}

.vessel-sidebar-p{
    font-size: 13px;
}

@media screen and (max-width: 1200px) {
    .vessel-parameter-modal {
        width: calc(100% - 100px);
        margin: 0 50px;
    }
}

@media screen and (max-width: 750px) {
    .vessel-table-month-card {
        padding: 10px 10px;
    }

    .vessel-table-card {
        padding: 10px 10px;
    }

    .select-tool-option {
        width: 100px;
    }

    .select-tool-option-wrap {
        padding: 12px 8px;
        font-size: 11px;
        font-weight: 600;
    }

    .select-tool-div {
        width: 450px !important;
    }

    .select-tool-button {
        padding: 10px 13px;
        font-size: 12px;
    }

    .query-modal {
        width: 450px;
    }
}

.parameter-button-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    gap: 30px;

}


@media screen and (max-width: 460px) {
    .select-tool-div {
        width: 300px !important;
    }
    .parameter-button-wrap{
        flex-wrap: wrap;
        flex-direction: column-reverse;
        gap: 0px;   
    }
    .browse-button-wrap{
        flex-direction: column;
        gap: 10px;  
    }
    .vessel-parameter-modal {
        width: calc(100% - 20px);
        margin: 0 10px;
    }
    .vessel-sidebar{
        width: 80px !important;
        padding: 15px 5px;
        }
    .vessel-sidebar-p{
        font-size:10px;
        text-align: center;
    }
    .vessel-sidebar-wrap{
        gap:20px !important;
        height: 160px !important;
    }
    .query-modal {
        width: 300px;
    }
    .query-modal-div{
        margin: 0 10px 10px 10px !important;
    }
    .css-ahj2mt-MuiTypography-root{
        font-size: 12px !important;
    }
}

.css-1iqpjhf{
    width: 300px !important;
}

.input-processing-align {
    display: flex;
    align-items: center;
    gap: 7px;
}

.vessel-table-card select{
    width: 150px !important;
}
.css-1ualgfl-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
    height: 35px !important;
}