.form-wrap {
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 100vh;

}

.forms-navbar {
    background-color: #17256E !important;
}

.form-card {
    width: 600px;
    margin: 60px auto;
    border-radius: 12px;
    padding: 0 50px;

}

.form-card-body {
    color: blue;
    padding: 50px 20px;
}

.form-card-title {
    font-weight: 700;
    color: #0A1967;
    font-size: 32px;

}

.form-label {
    color: #0A1967;
    font-size: 20px;
    font-weight: 400;

}

.form-input {
    border: none;
    border-bottom: 1px solid #17256E;
    padding-left: 0;
    margin-top: -6px;
    font-size: 18px;
    color: #A1A1A1;
    border-radius: 0;
}

.form-input:focus {
    outline:
        none;
    box-shadow: none;

}

.form-input-eye {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

}

.forgot-password-div {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;

}

.forgot-password-link {
    font-size: 16px;
    color: #17256E;
    text-decoration: none;

}

.form-button {
    border-radius: 12px;
    width: 300px;
    margin-top: 40px;
    border: none;
}

.form-end-label {
    color: #0A1967;
    font-size: 16px;
    margin-top: 20px;

}

.form-end-label-link {
    color: #0A1967;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
}

.form-p-wrap {
    margin-bottom: 45px;
    margin-top: 70px;
}

.form-p-text {
    font-size: 20px;
    font-weight: 400;
    color: #17256E;

}

@media screen and (max-width: 768px) {
    .form-card {
        width: 400px;
        padding: 0 30px;
    }

    .form-button {
        width: 220px;

    }

    .form-p-wrap {
        margin-bottom: 35px;
        margin-top: 50px;
    }
    .form-p-text {
        font-size: 16px;
    }
}

@media screen and (max-width: 500px) {
    .form-card {
        width: calc(100% - 60px);
        padding: 0 30px;
        margin: 30px 30px;
    }

    .form-button {
        width: 180px;

    }


    .form-card-title {
        font-size: 28px;
    }

    .form-label,
    .form-input {
        font-size: 15px;
        margin-bottom: 0;
    }

    .forgot-password-link {
        font-size: 13px;
    }

    .form-end-label {
        font-size: 12px;
        margin-bottom: 0;
    }

    .form-p-wrap {
        margin-bottom: 20px;
        margin-top: 35px;
    }
    .form-p-text {
        font-size: 13px;
    }
}

@media screen and (max-width: 450px) {
    .form-card {
        width: calc(100% - 20px);
        padding: 0 30px;
        margin: 30px 10px;
    }
}