.header-2 {
    border-radius: 5px;
    width: 100%;
    padding: 0 60px !important;
}


@media screen and (max-width: 600px) {
    .header-2 {
        padding: 0 20px !important;
    }
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 15px;

}

.header-2-logo {
    width: 100px;
    height: 50px;

}

.header-2-search {
    max-width: 300px !important;
    border-radius: 25px !important;
    background-color: #434e89 !important;
    opacity: 1;
    padding-left: 50px !important;
    color: white !important;
}

.header-2-ul {
    background-color: #434e89;
    color: white;
    border-radius: 25px;
    position: absolute;
    top: 60px;
    width: 300px;
    z-index: 1000;
}

.header-2-profile-section {
    display: flex;
    gap: 15px;
    justify-content: end;
    align-items: center;
    cursor: pointer;
}

.header-profile-name {
    font-size: 0.8em;
    color: white;
    margin-bottom: 4px;
}

.header2-logout {
    font-size: 0.6em;
    color: white;
    text-align: end;
    background: #3d4882;
    border: none;
    border-radius: 20px;
    padding: 0 10px;
    margin: 0;
    width: auto;
    text-wrap: nowrap;
}

.header-2-profile-pic {
    border-radius: 50%;
    width: 50px;
    height: 50px;

}

.profile-icon {
    width: 40px;
    height: 40px;
    color: grey;

}
.header-form-search-icon{
    position: absolute;
top: 6px;
left: 20px;

}
.header-2-profile-section.desktop{
    display: flex;
}
.header-2-profile-section.mobile{
    display: none;
}


@media screen and (max-width: 576px) {

    .profile-icon {
        display: none;

    }

    .header-2-profile-section.desktop{
        display: none;
    }
    .header-2-profile-section.mobile{
        display: flex;
    }
   
    .mini-screen-dropdown{
        padding: 10px;
        background-color: #f0f0f0;
        position: fixed;
        border-radius: 10px;
        width: 140px;
        top: 60px;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        display: none;
    }
    .mini-screen-dropdown.visible{
        display: block;
    }
    .header2-logout{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        padding: 5px;
        font-size: 12px;
        font-weight: 600;
        border-radius: 8px;
    }

    .signout-text {
    }

    .header-profile-name {
        color: #292d32;
        font-weight: 700;
        font-size: 13px;
    }
    .header-container {
        padding-right: 0px !important;
    }
    .header-2-profile-section{
        gap: 5px;
    }
    .header2-logout svg{
        font-size: 21px;
        padding: 3px 0;
    }
    .header-2-profile-pic {
        border-radius: 50%;
        width: 40px;
        height: 40px;
    }
    .header-2-logo {
        width: 70px;
        height: 35px;
    }
    .header-2-search{
        height: 30px;
        max-width: 100% !important;
        font-size: 12px !important;
    }
    .header-form-search-icon{
        top: 2px;
    }
    .header-form-search{
        display: flex;
        justify-content: center;
        margin-top: 4px;
    }
}

@media (max-width: 576px) {
    .col-order-xs-2 {
        order: 2;
    }

    .col-order-xs-3 {
        order: 3;
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .col-order-sm-2 {
        order: 2;
    }

    .col-order-sm-3 {
        order: 3;
    }
}
@media (max-width: 500px) {
    .header-2-logo{
        margin-left: 30px;
    }
}




.bars{
    position: fixed;
    top: 20px;
    z-index: 999999999;
    left: 25px;
    cursor: pointer;
    display:none;
}
.bars svg{
    font-size: 30px;
    color: #fff;
}



@media (max-width: 500px) {
    .bars{
        display: block;
        
    }
}